<template>
  
    <div>
         <div class="package_section receving_packages_close_box">
            <div class="container">
                <div class="table_wrap">
                    <div class="cell_wrap">
                        <div class="empty_package">
                            <img src="./casuals_couriers/images/thankyou_page_box.png" alt="Thank You">
                            <p>נשלחה ללקוח SMS הודעת </p>
                            <p>Simpo תודה שעשיתם שימוש בשירות </p>
                            <p>נתראה בחבילה הבאה</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    
</template>

<script>

    import BackendModel from "../../models/BackendModel";
    // import CasualCouriersBase from "./CasualCouriersBase";
    import('./casuals_couriers/css/style.css')

    export default {
      name: 'CasualCouriersThanks',
      components:{
      
      },
      data() {
        return {
        
      
            
        }
      },
       async mounted () {

       },

       

    }
</script>

<style>
@import "./casuals_couriers/css/style.css";
</style>